import { ref } from "vue";
import type { FAQ } from "~/types/faq";

const homePageFaq: FAQ[] = [
  {
    show: false,
    question: "What makes Nurture Life different?",
    answer:
      "Feeding your kid is hard. You have to think about whether they’ll like it, how long it’ll take you to make it, is it nutritious, do you have the ingredients, have you already fed them this same meal 14 times this month. And the list goes ON. Nurture Life is the only baby and kids meal delivery service that helps you tackle every single one of your mealtime needs.\n" +
      "\n" +
      "We prioritize balanced nutrition (including veggies in every meal!) while capturing the flavors that babies, toddlers and kids actually want to eat. Our flexible ordering system makes it easy to choose meals that fit your family’s ever-changing schedule—and so does the fact that every meal can be ready to eat in just 1 minute.\n" +
      "\n" +
      "We’re here for you as your baby learns to self-feed. We’re here for your toddler’s journey into more variety and bigger bites. We’re here for your kid’s ongoing healthy eating habits. And we’re here for every meal of the day. Breakfast, lunch, dinner and snack time, we’ve got you covered.\n" +
      "\n" +
      "Flexible, flavor-focused and absolutely uncompromising on nutrition. That’s Nurture Life.\n" +
      "\n",
  },
  {
    show: false,
    question: "Who is Nurture Life for?",
    answer:
      "<p>Feeding your kid is hard. You have to think about whether they’ll like it, how long it’ll take you to make it, is it nutritious, do you have the ingredients, have you already fed them this same meal 14 times this month. And the list goes ON. Nurture Life is the only baby and kids meal delivery service that helps you tackle every single one of your mealtime needs.<br> <br>\n" +
      "We prioritize balanced nutrition (including veggies in every meal!) while capturing the flavors that babies, toddlers and kids actually want to eat. Our flexible ordering system makes it easy to choose meals that fit your family’s ever-changing schedule—and so does the fact that every meal can be ready to eat in just 1 minute.<br> <br>\n" +
      "We’re here for you as your baby learns to self-feed. We’re here for your toddler’s journey into more variety and bigger bites. We’re here for your kid’s ongoing healthy eating habits. And we’re here for every meal of the day. Breakfast, lunch, dinner and snack time, we’ve got you covered.<br> <br>\n" +
      "Flexible, flavor-focused and absolutely uncompromising on nutrition. That’s Nurture Life.</p>",
  },
  {
    show: false,
    question: "How does Nurture Life work?",
    answer:
      "<p>Nurture Life is for busy parents who want more time in their full (and ever fuller!) daily lives—without having to make sacrifices about what they’re feeding their kids. We take the stress out of meal preparation by providing healthy, ready-to-eat meals that babies, toddlers and kids actually want to eat.<br> <br>\n" +
      "Our delicious baby and kids meals are freshly made for all the little ones in your home. From babies who are just learning to grab at the high chair tray to active toddlers and kids who need a healthy, balanced meal to fuel them throughout the day, Nurture Life is a palate pleaser. Even picky eaters can find a favorite on our kids meal delivery menu!<br> <br>\n" +
      "We deliver meals your family will love, and you get time back in your busy life—exactly what every parent needs. </p>",
  },
  {
    show: false,
    question: "What are Nurture Life’s nutritional standards?",
    answer:
      "<p>All of our meals are designed by registered dietitians, ensuring we always meet exceptionally high nutrition standards and follow our meal philosophy:<br>\n" +
      "- Delicious AND nutritious<br>\n" +
      "- Veggies in every meal<br>\n" +
      "- Good source of protein<br>\n" +
      "- Real, organic ingredients<br>\n" +
      "- Fresh, never frozen<br> <br>\n" +
      "You can feel good knowing we also have strict standards for what DOESN’T go into our meals:<br>\n" +
      "- No trans fat<br>\n" +
      "- No high fructose corn syrup<br>\n" +
      "- No artificial colors or flavors<br>\n" +
      "- No nitrates<br> <br>Plus, we keep sugar and salt low so your kid can enjoy the natural deliciousness of our real, clean ingredients.With every meal we put on the Nurture Life menu, we’re raising the standard for what goes on your kids’ plates!<br></p>",
  },
  {
    show: false,
    question: "Is Nurture Life picky eater-friendly?",
    answer:
      "<p>Yes! We know that picky eating is an obstacle to healthy eating, so we’ve made picky eater-friendly meals that still maintain our high nutritional standards.<br> <br>\n" +
      "To find picky eater meals, use the filters on <u><a href=\"https://www.nurturelife.com/menus/\">our menu</a></u> and look for the Picky Eater Fav banner. You’ll find a variety of deliciously balanced kid favorites, including Mac &amp; Cheese with Hidden Butternut Squash and Chicken Meatballs with Spiral Pasta &amp; Veggies. Many of these options smoothly blend veggies right into the sauce so your kid won’t even notice the veggies are there!<br> <br>\n" +
      "Nurture Life is also here to help your picky eater become not-so picky! Our menu features meals that can help expose picky eaters to new colors, textures and tastes. We recommend gradually incorporating meals from outside of our Picky Eater Favorites to familiarize your child with a wider variety of flavors. Remember that it can take 9–15 tries for kids to accept a new food, so stick with it! You’re doing the right thing, and patience does pay off.<br> <br>\n" +
      "Whether you order our kids meal delivery or not, we also offer a ton of picky eater resources for you to draw from. When you feel like you just can’t handle another “no” at the table, check out our helpful resources from experts on <u><a href=\"/blog/category/picky-eaters/\">overcoming picky eating together</a></u> or contact us at support@nurturelife.com for help from one of our registered dietitians!</p>",
  },
  {
    show: false,
    question: "Is Nurture Life allergy-friendly?",
    answer:
      "<p>All our meals are made in a facility free of peanuts and tree nuts (except coconut), so we’re a great option for families with nut allergies! We also offer many Finger Foods, Kids Meals and Snacks that are free from common food allergens like egg, milk, wheat, fish, soy and sesame.<br> <br>\n" +
      "Our kitchen does process coconut, milk, eggs, fish (salmon), soy, wheat and sesame. We recommend that you closely review our full ingredient lists and the “contains” statement associated with each of our meals if you have a family member with a food allergy. From <u><a href=\"/menus/\">our menu</a></u>, these can be found by clicking on any meal for further details. All our meal packaging also includes full ingredient lists.<br> <br>\n" +
      "While we always follow best practices for allergen controls, if your child has celiac disease, Nurture Life might not be the right option for you. Please contact us if you have any questions about your specific needs at support@nurturelife.com or 877.988.8851.</p>",
  },
  {
    show: false,
    question: "Does Nurture Life’s menu change?",
    answer:
      "<p>Every week, we offer a core set of customer favorite meals that never change. At the same time, we keep things fresh and interesting by consistently introducing new flavors for your kids to try! We launch exciting new meals every 3 months and always have at least 25 Kids Meals, 12 Finger Foods and a selection of deliciously cookie-inspired Snacks on the menu.<br> <br>\n" +
      "This mix of familiar and new gives you a wide variety of nutritious meals to choose from, each containing a diverse range of organic veggies and antibiotic- and hormone-free proteins. You’ll be able to select the right meals for your baby, toddler or kid at every stage of their eating journey—whether your little one is currently on an adventurous streak or is stuck in that “mac and cheese only” phase. Our <u><a href=\"/menus/\">healthy menu</a></u> has something for everyone.<br> <br>\n" +
      "You also have a say in what comes next on the Nurture Life menu. We’re honored to be invited to your family’s table, and we take your feedback seriously as we consider changes to our meals. So please share your feedback with our team at support@nurturelife.com and remember to check back frequently to see what’s fresh!</p>",
  },
  {
    show: false,
    question: "Can I change meals in my subscription?",
    answer:
      "<p>Yes! You define the quantity of meals in your order each week, and we fill your order with recommended meals based on your selection, but you can always change our meal recommendations. You can also customize your account to get the best recommendations! We make it easy for you to set your account and forget it, or if you like being more hands on, you can always head into your account and switch up your order or skip a week.<br> <br>\n" +
      "Please note that in order to fulfill our promise of fresh, not frozen, we do have a cutoff deadline for each order. If you change your family’s preferences after an order cutoff has already passed, that order will be filled based on your previous preferences. All future orders will be filled based on your new preferences.<br> <br>\n" +
      "Not sure when the cutoff is? You can find this information in the <u><a href=\"http://account.nurturelife.com/orders\">orders</a></u> section of your account.</p>",
  },
  {
    show: false,
    question: "How much does Nurture Life cost?",
    answer:
      "<p>Nurture Life is super flexible to accommodate whatever your family needs. Our meals are priced individually, with Finger Foods at $6.89, Kids Meals starting at $7.99 and Snacks at $7.99. Simply choose a plan based on the number of meals you’re interested in, and your total cost is based on the mix of products you order. We start at a 6 meal plan, with free shipping on 9+ meal plans and discounts of up to $15 off your entire order:<br> <br>\n" +
      "- 6 Meal Plan<br>\n" +
      "- 9 Meal Plan = free shipping<br>\n" +
      "- 12 Meal Plan = $10 off your entire order + free shipping<br>\n" +
      "- 15 Meal Plan = $15 off your entire order + free shipping</p>",
  },
  {
    show: false,
    question: "How do I get started?",
    answer:
      "<p>It’s simple! Just choose the meals and snacks you want to try from <u><a href=\"/menus/\">our menu</a></u> and check out.<br> <br>\n" +
      "Once you place your order, it’s time for us to get cooking! Meals typically arrive within a week to a week and a half depending on the day you signed up and your delivery location. You will be able to view your first and future scheduled delivery days in your account. Once your meals are on their way, we will send an email with the tracking information so your family can excitedly anticipate your first kids meal delivery box!<br> <br>\n" +
      "Get started building your first <u><a href=\"/menus/\">order</a></u> today.</p>",
  },
];

const howItWorksFaq: FAQ[] = [
  {
    question: "What makes Nurture Life different?",
    answer: `
          Nurture Life makes delicious, nutritious food for babies, toddlers
          and kids and delivers it straight to your door—eliminating the
          stress of meal prep and giving you precious time back in your busy
          day. In addition to well-balanced meals, we offer delicious Mighty
          Bites (cookie-inspired snacks!) and naturally sweet Superfood
          Smoothies.<br>
          <br>
          Convenience aside, we’re proud of what makes us different from other
          kids meal delivery companies:<br>
          <br>
          - Uncompromising focus on nutrition<br>
          - Super-flexible ordering system<br>
          - Diverse, kid-friendly flavors that even picky eaters can delight
          in!<br>
          <br>
          We balance every meal with guidance from our registered dietitians,
          and we make sure that veggies are found on every plate. We use the
          same high-quality ingredients you’d use in your own kitchen, except
          our meals are ready to be loved in just 1 minute! Snacks and
          smoothies can be grabbed right from the fridge.
        `,
    show: false,
  },
  {
    question: "Who is Nurture Life for?",
    answer: `
          If your busy schedule makes it difficult to make
          <u class="tw-border-gray-100 tw-border-solid tw-border-0 tw-box-border tw-font-semibold tw-text-sm tw-leading-5 tw-text-left tw-text-green-900 tw-tracking-wide">
            <a href="/menus/">healthy food that your little one will actually eat</a>
          </u>, then Nurture Life is for you. We offer a
          varied menu of
          flavorful, well-balanced, thoughtfully portioned meals for babies,
          toddlers and kids—plus snacks and smoothies to fill those
          between-meal needs! While all of our menu items are packed with
          irresistibly kid-friendly flavors, they still serve up the clean,
          nutritious ingredients that kids need to grow up healthy and
          happy.<br>
          <br>
          When you need one less thing on your plate, Nurture Life is here. We
          deliver freshly made food that your kids will love, and our flexible
          ordering system makes your day-to-day easier—exactly what every
          parent needs.
        `,
    show: false,
  },
  {
    question: "Is there an order minimum?",
    answer: `
          Yes, you must order a minimum of 6 items (Finger Foods, Kids Meals,
          Mighty Bites or Superfood Smoothies!). This helps us cover our
          packaging and shipping costs.<br>
          <br>
          You can also receive discounts by choosing a larger plan:<br>
          - 10 items = $5 OFF your entire order<br>
          - 13 items = $10 OFF your entire order<br>
          - 16 items = $15 OFF your entire order<br>
          <br>
          Browse our
          <u class="tw-border-gray-100 tw-border-solid tw-border-0 tw-box-border tw-font-semibold tw-text-sm tw-leading-5 tw-text-left tw-text-green-900 tw-tracking-wide">
            <a href="/menus/">healthy meal delivery menu</a>
          </u>
          and start your order today.
        `,
    show: false,
  },
  {
    question: "How does Nurture Life’s kids meal subscription service work?",
    answer: `
          The process is simple! You choose a plan size and select nutritious,
          delicious meals, snacks and smoothies from our menu. Our plans start
          at 6 meals, with greater discounts as you add more meals:<br>
          <br>
          - 10 items = $5 OFF your entire order<br>
          - 13 items = $10 OFF your entire order<br>
          - 16 items = $15 OFF your entire order<br>
          <br>
          Once you’ve placed your order, our chefs get to work! We’ll prepare
          your kids’ food using the freshest ingredients with a focus on
          organic veggies and antibiotic- and hormone-free proteins. Within
          just about a week, your box will arrive fresh—cold but never
          frozen.<br>
          <br>
          Each week, we automatically fill your order with suggested meals
          based on your preferences, but you can log into your Nurture Life
          <u class="tw-border-gray-100 tw-border-solid tw-border-0 tw-box-border tw-font-semibold tw-text-sm tw-leading-5 tw-text-left tw-text-green-900 tw-tracking-wide">
            <a href="https://account.nurturelife.com/">account</a>
          </u>
          to change what’s coming in your next order, skip that week, pause
          your account or cancel. Our healthy meal delivery service is
          designed to work for your family, no matter what your routine looks
          like.
        `,
    show: false,
  },
  {
    question: "Do I have to order every week?",
    answer: `
          Nope! We make it easy for you to skip weeks, pause or cancel
          altogether so you have the flexibility you need for your family’s
          schedule. We just ask that any changes to a weekly order be made
          before your order cutoff date so that we can purchase the right
          amount of ingredients to make your meals, snacks and smoothies. (You
          can find your cutoff information in the
          <u class="tw-border-gray-100 tw-border-solid tw-border-0 tw-box-border tw-font-semibold tw-text-sm tw-leading-5 tw-text-left tw-text-green-900 tw-tracking-wide">
            <a href="https://account.nurturelife.com/">orders</a>
          </u>
          section of your account.)<br/>
          <br/>
          Get started building your first
          <u>
            <a href="/menus/">order</a>
          </u>
          today.
        `,
    show: false,
  },
  {
    question: "What are Nurture Life’s nutritional standards?",
    answer: `
          With dietitian-designed meals, snacks and smoothies, our food
          philosophy is uncompromising:<br/>
          <br/>
          - Delicious AND nutritious<br/>
          - Veggies in every meal<br/>
          - Good source of protein<br/>
          - Real, organic ingredients<br/>
          - Fresh, never frozen<br/>
          <br/>
          Just as much as we care about what goes into our food, we care about
          what DOESN’T go in:<br/>
          <br/>
          - No trans fat<br/>
          - No high fructose corn syrup<br/>
          - No artificial colors or flavors<br/>
          - No nitrates<br/>
          <br/>
          Plus, we keep sugar and salt low so your kid can enjoy the natural
          deliciousness of our real, clean ingredients. (For example, our
          Superfood Smoothies have no added sugar at all!) We pay such close
          attention to our food because it’s what your kids (and you!)
          deserve. We never compromise on nutrition and are constantly working
          to source the freshest and best ingredients for babies, toddlers and
          kids.
        `,
    show: false,
  },
  {
    question: "How do I prepare Nurture Life meals?",
    answer: `
          No prep work necessary! Simply heat our Kids Meals in the microwave
          for a meal ready in 1 minute, or place them in an oven-safe
          container and heat them in the oven. Our Finger Foods can be enjoyed
          hot or cold, but if you do heat them up, they should be transferred
          to a heat-safe container first. Our Mighty Bite snacks and Superfood
          Smoothies can be savored right from the fridge with no prep at
          all.<br/>
          <br/>
          For full meal info including use by dates, heating instructions,
          nutrition facts and ingredients, just check the packaging!
        `,
    show: false,
  },
  {
    question: "Does Nurture Life’s menu change?",
    answer: `
          Yes and no. Our core
          <u>
            <a href="/menus/">menu</a>
          </u>
          offers a set of customer favorites that never change. These meals,
          snacks and smoothies are on our menu every week, ensuring that your
          kids’ go-to foods are always available.<br/>
          <br/>
          To keep things fresh, however, we also consistently introduce new
          meals to the Nurture Life menu. Every couple of months, you can
          expect to see delicious new meals with creative flavors, clean
          ingredients and—of course—veggies on every plate!<br/>
          <br/>
          This gives your kids the opportunity to try even more ingredients,
          flavors and textures—familiarizing them with a variety of foods and
          helping expand their palates from an early age. Between our core
          meals and our new ones, we always have at least 25 Kids Meals, 12
          Finger Foods, plus snacks and smoothies that are made to be enjoyed
          straight from the fridge!<br/>
          <br/>
          By giving us feedback on your order, you also have an important say
          in the changes we make to our healthy meal delivery menu. If you
          have a suggestion or request, we want to hear it at
          support@nurturelife.com!
        `,
    show: false,
  },
];

const orderSuccessFaq: FAQ[] = [
  {
    show: false,
    question: " How do I store my meals and snacks?",
    answer:
      "Keep Kids Meals, Finger Foods, Oaties, and Superfood Smoothies in your refrigerator. Store Super Sammies in the freezer until you’re ready to thaw and serve. Mighty Bites can be stored in a cool, dry place, like your pantry or cupboard.",
  },
  {
    show: false,
    question: "How do I heat the meals?",
    answer:
      "For Kids Meals, pierce the film seal and microwave on high for 1 minute. For Finger Foods, transfer to a microwave safe container and heat for 20–40 seconds. If you’d prefer to use an oven, both Kids Meals and Finger Foods can be heated in an oven-safe container at 350° F (10–15 minutes for Kids Meals, 5–12 minutes for Finger Foods).",
  },
  {
    show: false,
    question:
      "How long do Nurture Life meals and snacks last once they arrive?",
    answer:
      `<table>
              <tbody>
                <tr>
                  <th>Product</th>
                  <th>Storage</th>
                  <th>Shelf Life Unopened</th>
                  <th>Once Opened</th>
                <tr>
                <tr>
                  <td>Kids Meals</td>
                  <td>Refrigerator</td>
                  <td>5–8 days</td>
                  <td>24 hours</td>
                </tr>
                <tr>
                  <td>Finger Foods </td>
                  <td>Refrigerator</td>
                  <td>5–8 days</td>
                  <td>24 hours</td>
                </tr>
                <tr>
                  <td>Oaties</td>
                  <td>Refrigerator</td>
                  <td>5–8 days</td>
                  <td>3 days</td>
                </tr>
                <tr>
                  <td>Superfood Smoothies</td>
                  <td>Refrigerator</td>
                  <td>2 weeks +</td>
                  <td>3 days</td>
                </tr>
                <tr>
                  <td>Super Sammies</td>
                  <td>Freezer</td>
                  <td>6 months +</td>
                  <td>8–10 hours (once thawed)</td>
                </tr>
                <tr>
                  <td>Mighty Bites</td>
                  <td>Pantry / Cabinet</td>
                  <td>4 weeks +</td>
                  <td>2–4 weeks + (keep the bag sealed)</td>
                </tr>
              </tbody>
            </table>` +
      "<p>Kids Meals, Finger Foods, Oaties and Superfood Smoothies can also be frozen for up to 90 days. For full freezing information, visit our blog: <a target='_blank' href='https://www.nurturelife.com/blog/how-to-freeze-meals-from-nurture-life/'>How to Freeze Meals from Nurture Life.</a>" +
      "<p>Please reference Enjoy By dates on all packaging.</p>",
  },
  {
    show: false,
    question: "When will I get a confirmation email?",
    answer:
      "You will receive an email shortly after signing up with information about your subscription. Tracking information will be sent via email the day before your order arrives.",
  },
];

const faqSets = {
  home: homePageFaq,
  howItWorks: howItWorksFaq,
  orderSuccess: orderSuccessFaq,
} as const;

export type FaqType = keyof typeof faqSets;

export function useFaq(faqType: FaqType) {
  const faqs = ref<FAQ[]>(structuredClone(faqSets[faqType]));

  const toggleAnswer = (selectedFaq: FAQ) => {
    faqs.value = faqs.value.map((faq) => ({
      ...faq,
      show: faq === selectedFaq ? !selectedFaq.show : false,
    }));
  };

  return {
    faqs,
    toggleAnswer,
  };
}
